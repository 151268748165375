import Head from 'next/head'
import { withAuthUser, AuthAction } from 'next-firebase-auth'
import styles from './login.module.css'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { Button, Form, notification, Switch } from 'antd'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { GoogleLogin } from 'react-google-login'
import { useRouter } from 'next/router'
import InputSecondary from '../../components/input-secondary'

const LoginPage = () => {
	const [isRememberChecked, setIsRememberChecked] = useState(true)
	const [isEmailPasswordLogin, setIsEmailPasswordLogin] = useState(false)
	const [isGoogleLogin, setIsGoogleLogin] = useState(false)
	const [formErrorMessage, setFormErrorMessage] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const router = useRouter()

	useEffect(() => {
		if (isRememberChecked) {
			firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		} else {
			firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
		}
	}, [isRememberChecked])

	useEffect(() => {
		if (!email) return
		isValidEmail(email) ? setFormErrorMessage('') : setFormErrorMessage('Invalid Login Credential')
	}, [email])

	const isValidEmail = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	}

	const onFinish = async () => {
		try {
			if (!email || !password) {
				!email && setEmail(null)
				!password && setPassword(null)
				return
			}
			if (!isValidEmail(email)) return setFormErrorMessage('Invalid Login Credential')

			setIsEmailPasswordLogin(true)
			if (formErrorMessage) setFormErrorMessage('')
			await firebase.auth().signInWithEmailAndPassword(email, password)
		} catch (e) {
			setIsEmailPasswordLogin(false)
			e.code === 'auth/network-request-failed' ?
				setFormErrorMessage('Internet Connection Error') :
				setFormErrorMessage('Login Credential Mismatch')
		} finally {
			setIsEmailPasswordLogin(false)
		}
	}

	const onGoogleSignIn = async (response) => {
		try {
			if (!response.error) {
				setIsGoogleLogin(true)
				const profile = response.getBasicProfile()
				const email = profile.getEmail()
				const methods = await firebase.auth().fetchSignInMethodsForEmail(email)
				const idToken = response.getAuthResponse().id_token
				if (methods.length === 0) {
					// User does not exist. Go to sign up flow.
					const name = profile.getName()
					router.push(`/sign-up?email=${email}${name ? `&name=${name}` : ''}${idToken ? `&idToken=${idToken}` : ''}`)
				} else {
					// User does exist. Sign in like usual.
					const credential = firebase.auth.GoogleAuthProvider.credential(idToken)
					await firebase.auth().signInWithCredential(credential)
				}
			}
		} catch (e) {
			setIsGoogleLogin(false)
			notification.error({
				message: 'Login Error',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsGoogleLogin(false)
		}
	}

	return (
		<>
			<Head>
				<title>Nuport</title>
				<meta name='description' content='Designed For High Performance Field Procurement & Distribution Planning Teams.' />
				<link rel='icon' href='/favicon.ico' />
			</Head>

			<main className={`${styles.root} login-page`}>
				<Form
					className={styles.loginForm}
					name='basic'
					initialValues={{
						remember: true
					}}
					onFinish={onFinish}
				>
					<div className={styles.logo}>
						<Image priority src='/img/nuport-logo.svg' width={216} height={24} alt='Nuport logo' />
					</div>
					{
						formErrorMessage && <div className={styles.errorMessage}>{formErrorMessage}</div>
					}
					<div className={styles.inputsContainer}>
						<InputSecondary
							type='text'
							name='email'
							placeholder='Email or Phone Number'
							label='Email or Phone'
							error={formErrorMessage}
							errorMessage='Enter an email or phone number'
							value={email}
							onChange={setEmail}
						/>
						<InputSecondary
							type='password'
							name='password'
							placeholder='Password'
							label='Password'
							error={formErrorMessage}
							errorMessage='Enter a password'
							value={password}
							onChange={setPassword}
						/>
					</div>
					<div className={styles.rememberBtnContainer}>
						<p className={styles.rememberText}>Remember</p>
						<Switch
							checked={isRememberChecked}
							onChange={setIsRememberChecked}
						/>
						<Link href='/forgot-password'>
							<a className={styles.forgotPassword}>Change/Forgot Password?</a>
						</Link>
					</div>
					<Button
						className={styles.loginBtn}
						loading={isEmailPasswordLogin}
						size='large'
						type='primary'
						htmlType='submit'
					>
						Log In
					</Button>
					<div className={styles.signupLinkContainer}>
						No Account?
						<Link href='/sign-up'>
							<a className={styles.signupLink}> Create</a>
						</Link>
					</div>
					<div className={styles.orTextContainer}>
						<div className={`${styles.line} ${styles.lineLeft}`} />
						<span>Or</span>
						<div className={`${styles.line} ${styles.lineRight}`} />
					</div>
					<GoogleLogin
						clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
						buttonText='Login'
						onSuccess={onGoogleSignIn}
						onFailure={onGoogleSignIn}
						render={renderProps => {
							return (
								<Button
									className={styles.googleSignupBtn}
									loading={isGoogleLogin}
									size='large'
									htmlType='submit'
									onClick={renderProps.onClick}
								>
									<img src='/img/google.svg' alt='Google logo' />
									<span>
										Continue with Google
									</span>
								</Button>
							)
						}}
						cookiePolicy={'single_host_origin'}
					/>
				</Form>
			</main>
		</>
	)
}

export default withAuthUser({
	whenAuthed: AuthAction.REDIRECT_TO_APP,
	whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
	whenUnauthedAfterInit: AuthAction.RENDER
})(LoginPage)
